import React from 'react';
import Layout from '../components/Shared/Layout';
import Expertise from '../components/Expertise';
import '../assets/css/services.css';
import * as ReactGA from 'react-ga';

const $ = typeof window !== `undefined` ? require('jquery') : null;

class ExpertisePage extends React.Component {
  componentDidMount() {
    ReactGA.set({
      title: 'Expertise.Page',
    });
    ReactGA.pageview(this.props.location.pathname);
  }

  render() {
    return (
      <Layout>
        <Expertise />
      </Layout>
    );
  }
}

export default ExpertisePage;
